import React, { useEffect, useState } from 'react'
import Button from '../../Button/Button'
import CustomUploadBox from '../../CustomUploadBox/CustomUploadBox'
import './CSVPopupStyle.css';
import InputField from '../../InputField/InputField';
import { clearState, addMastersCSV, downloadSampleMasterCSV, getAllMasters, masterSliceData } from "../../../reducer/masterSlice";
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from "react-csv";
import PageLoader from "../../PageLoader/PageLoader"
import ErrorToast from '../../Toast/ErrorToast'
import SuccessToast from '../../Toast/SuccessToast'


const CSVPopup = ({ showAddDetails2, setShowAddDetails2 }) => {
  const dispatch = useDispatch()
  const { isFetching, isError, isSuccess, errorMessage, csvData } = useSelector(masterSliceData);
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  useEffect(() => {
    // dispatch(downloadSampleMasterCSV())
  }, [])


  let [file, setFile] = useState("");

  const handleChange = (e) => {
    
    if (e.target.files.length > 0) setFile(e.target.files[0])
  }

  const handleOnClick = () => {

    if (file != "") {
      const obj = {
        file: file
      }
      dispatch(addMastersCSV(obj));
    }
  }

  const onClickDelete = () => {
    setFile("")
  }

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
      const obj = {
        focusAreas: "",
        currentLocation: "",
        engagement: "",
        search: "",
        pageNo: 1
      };
      dispatch(getAllMasters(obj));

    }

  }, [])

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearState());
      const obj = {
        focusAreas: "",
        currentLocation: "",
        engagement: "",
        search: "",
        pageNo: 1
      };
      setShowAddDetails2(false)
      setFile("")
      dispatch(getAllMasters(obj));
      setIsShowError(true);
      setErrMessage(errorMessage);
      //  console.log("mssssg", errorMessage);

    }
  }, [isSuccess]);


  return (
    <>
      {isFetching && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <SuccessToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <div className={`popupWrapper  ${showAddDetails2 ? 'active' : ""}`}>
        <div className="popupContainer CSVWrapper">
          <div className="popupHeader">
            <p className="popupHeading">Add CSV</p >
            <span className="close" onClick={() => { setShowAddDetails2(false) }}><img src="/assets/svg/close.svg" alt="" /></span>
          </div>
          <div className="popupBody">
            <CustomUploadBox
              file={file}
              onChange={handleChange}
              value={file.name}
              onClick={onClickDelete}
              csvUpload={true}
            />
          </div>
          <div className="popupFooter popupAddcsv mt-20">
            <div className="buttonWrapper">
              {/* <CSVLink data={csvData ? csvData : ""}>
              Download Sample File
            </CSVLink> */}
              <Button className={'GreyFillButton'} text={'Download Sample File'} onClick={() => window.open("/assets/sampleCSVfrontend.csv")} />

              <div className='footerButton'>
                <Button className={'GreyFillButton'} text={'Cancel'} onClick={() => { setFile(""); setShowAddDetails2(false) }} />
                <Button className={'BlueFillButton'} text={`Upload`} onClick={() => handleOnClick()} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CSVPopup