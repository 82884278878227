import React from 'react';
import './CustomRadioButtonStyle.css';

const CustomRadioButton = ({name,text,className,onChange,value,checked}) => {
	return (
    <div className={`radioWrap ${className}`}>
      <label class="labelWrap">
        <input type="radio" value={value} name={name} checked={checked} onChange={onChange}/>
        <span className="radioText">{text}</span>
        <span class="checkmark" ></span>
      </label>
    </div>
	);
};

export default CustomRadioButton;
