import React from 'react'
import './CustomDropdownStyle.css';

const CustomDropdown = ({data, dropName, options,isActive,setIsActive,className,label,isimp, onChange,value,name,errorMessage}) => {
// console.log("xoxox",data);
  return (
    <div className={`customDropdownWrap flexbox ${isActive && 'active'} ${className}`} onClick={() => setIsActive(prev => !prev)}>
        {label && <label>{label} {isimp && <span className="impRed">*</span>}</label>}
        <div className="dropDrop flexbox">
            <div className="dropText">{data !== "" ? data : dropName}</div>
            {/* <div className="dropText">{dropName}</div> */}
            {/* <img src="/assets/svg/arrow-down.svg" alt="" /> */}
            <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.62 0.220703L5.81667 4.02404C5.3675 4.4732 4.6325 4.4732 4.18334 4.02404L0.380005 0.220703" fill="#525252"/>
            </svg>

        </div>
        {errorMessage && <span className='FormError'>{errorMessage}</span>}
        <div className="dropMenuWindow">
        
        {options?.map((item,index) => (
            <label class="dropMenu" key={index} >
                <input type="radio" name={name} value={item} onChange={(e) => onChange(e)} onClick={() => setIsActive(prev => !prev)}/>
                <span class="menuList">{item}</span>
            </label>
        ))}

        </div>
    </div>
  )
}

export default CustomDropdown