import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearState,
  loginUser,
  masterSliceData,
} from "../../reducer/masterSlice";
import PageLoader from "../../Components/PageLoader/PageLoader";
import "./LoginStyle.css";
import { useNavigate } from "react-router-dom";
import ErrorToast from "../../Components/Toast/ErrorToast";

function LoginPage() {
  const navigate = useNavigate();

  const [checked, setChecked] = useState(false);
  const [username, setUsernameInput] = useState("");
  const [validateEmail, setEmailValidation] = useState(false);
  const [validatePassword, setPasswordValidation] = useState(false);
  const [password, setPasswordInput] = useState("");
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const dispatch = useDispatch();

  const COMMON_TEXT = {
    loginText: "Login",
    forgetText: "Submit",
    resetText: "Confirm",
    forgotPassword: "Forget Password?",
    keepLogin: "Remember my login details",
    passwordAlert: "Please enter password",
    enterPassword: "Enter Password",
    emailAlert: "Please enter valid email",
    enterEmail: "Enter Email",
    welcomeStudent: "Welcome Student",
    loginToAccount: "Please login to your Account",
    forgetAdmin: "User forget password",
    forgetAdminText: "Please your Email",
    backToLogin: "Back to Login",
    resetAdmin: "Reset your Password",
    resetAdminText: "Please Reset your Password",
    enterCnfPassword: "Enter Comfirm Password",
    questionDetails: "Question Details",
    goBack: "Back to page",
  };

  const { isFetching, isSuccess, isError, errorMessage } =
    useSelector(masterSliceData);

  // /**VERIFY EMAIL ON TYPE */
  const onChangeEmail = (event) => {
    var mEmailAddress = event.target.value;
    setUsernameInput(event.target.value);
    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (mEmailAddress === "") {
      setEmailValidation(true);
    } else if (!filter.test(mEmailAddress)) {
      setEmailValidation(true);
    } else {
      setEmailValidation(false);
    }
  };

  // /**VERIFY PASSWORD ON TYPE */
  const onChangePassword = (event) => {
    var mPassword = event.target.value;
    setPasswordInput(event.target.value);
    if (mPassword === "") {
      setPasswordValidation(true);
    } else {
      setPasswordValidation(false);
    }
  };

  const onsubmit = (e) => {
    e.preventDefault();
    if (username && password) {
      var data = {
        email: username,
        password: password,
      };
      dispatch(loginUser(data));
    } else {
      // toast.error("Both fields must not be empty !");
      // localStorage.removeItem("token");
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    let isAuth = localStorage.getItem("token");
    if (isAuth && isAuth !== "undefined" && isAuth != "null") {
      navigate("/", { replace: true });
    }
  }, []);

  useEffect(() => {
    if (isError) {
      // toast.error(errorMessage);
      setIsShowError(true);
      setErrMessage(errorMessage);
      dispatch(clearState());
    }

    if (isSuccess) {
      dispatch(clearState());
      navigate("/", { replace: true });
    }
  }, [isError, isSuccess]);

  // useEffect(()=>{
  //   if(id && pass){
  //     var data = {
  //       email: id,
  //       password: pass,
  //     };
  //     dispatch(loginUser(data));
  //   }
  // },[])

  return (
    <>
      {isFetching && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      {!checked && (
        <div className="loginContainer">
          <div className="loginForm form-1">
            <div className="loginTop form-1-top">
              <h1 className="heading-1">Welcome Admin</h1>
              <p className="text-1a">{COMMON_TEXT.loginToAccount}</p>
            </div>
            <div className="mt-40 loginCenter">
              <form id="login">
                <div className="form-outer">
                  <div className="form-input">
                    <div className="form-group">
                      <div className="input-group">
                        <input
                          type="text"
                          placeholder="Enter your Email"
                          id="name"
                          name="name"
                          className="text-3 input-box mt-5"
                          onChange={onChangeEmail}
                        />
                        <label htmlFor="name" className="text-2 form-label">
                          {COMMON_TEXT.enterEmail}
                        </label>
                      </div>
                      {validateEmail && (
                        <span className="msg-error" id="nameValidation">
                          {COMMON_TEXT.emailAlert}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="mt-30 form-input">
                    <div className="form-group">
                      <div className="input-group">
                        <input
                          type="password"
                          placeholder="Enter your Password"
                          id="pass"
                          name="pass"
                          className="text-3 input-box mt-5"
                          onChange={onChangePassword}
                        />
                        <label htmlFor="pass" className="text-2 form-label">
                          {COMMON_TEXT.enterPassword}
                        </label>
                      </div>
                      {}{" "}
                      {validatePassword && (
                        <span className="msg-error" id="passValidation">
                          {COMMON_TEXT.passwordAlert}
                        </span>
                      )}
                    </div>
                  </div>
                  {/* <div className="mt-15 form-input checkbox">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        className="checkmark-btn"
                        onClick={() => {
                          setChecked(!checked);
                        }}
                        defaultChecked={checked}
                        // checked={checked}
                      />
                      <span className="checkmarkspan" />
                      {COMMON_TEXT.keepLogin}
                    </label>
                  </div> */}

                  <div className=" mt-40 form-input">
                    <div className="">
                      <button
                        className="button loginButton blueButton btn-lg"
                        onClick={onsubmit}
                      >
                        {COMMON_TEXT.loginText}
                      </button>
                    </div>
                  </div>
                  {/* <div className="form-input">
                    <a
                      className="mt-10 red-alert"
                      onClick={() => setChecked(true)}
                    >
                      {COMMON_TEXT.forgotPassword}
                    </a>
                  </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default LoginPage;
