import React from 'react'
import './InputFieldStyle.css';

const InputField = ({type,className,placeholder,id,labelName,checkBoxWrap, onChange,disabled, value, checked, errorMessage,notImp, onKeyPress, blackLabel, name}) => {
 
    return (
      
      <div className={`formGroups ${checkBoxWrap??""}`}>
            <label className={blackLabel && 'visible0'} htmlFor={id}>{labelName}  
                {!notImp && <span className={"impRed"}> *</span>}
            </label>
            <input type={type} className={className} placeholder={placeholder} name={name} onKeyPress={onKeyPress} disabled={disabled} value={value} onChange={onChange} id={id} checked={checked}/>
            {errorMessage && <span className='FormError'>{errorMessage}</span>}
      </div>
    )
  }

export default InputField