import React from 'react'
import './MasterExistPopupStyle.css'
import Button from '../../Button/Button'
import { useNavigate } from "react-router-dom";

const MasterExistPopup = ({alreadyExist, setAlreadyExist, emasterId}) => {

    const navigate = useNavigate();

    return (
        <div className={`popupWrapper  ${alreadyExist? 'active':''}`}>
            <div className="popupContainer  MasterExistBody">
                <div className="popupHeader">
                    <p className="popupHeading">Master Profile Already Exist</p >
                    <span className="close"><img src="/assets/svg/close.svg" alt=""  onClick={()=>{setAlreadyExist(false)}}/></span>
                </div>

                <div className="ContentBody">

                    <div className="textMnExit">
                     A Master profile with entered contact number already exists .
                    </div>

                    <div className='footerButton'>
                        <Button className={'BlueFillButton'} text={'Edit Existing Profile'}  onClick={(e) => emasterId ? navigate( "/aboutMaster/" + emasterId) : ''}
/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MasterExistPopup