import React from 'react'
import InputField from '../../InputField/InputField'

const MasterForm1 = ({isActive,currentWorkCount,setCurrentWorkCount, setCurrentWork, onChange, handleCurrentWorkChange, peviousWorkCount,handlePreviousWorkChange, setPrevioustWorkCount, setPreviousWork,previousWork,currentWork}) => {

    const currentWorkTemplate = {
        currentDesignation: "",
        currentCompany: "",
        currentLocation: ""
    };

    const previousWorkTemplate = {
        previousDesignation: "",
        previousCompany: "",
        previousLocation: ""
    };

    return (
        <div className={`formRight ${isActive && 'active'}`}>
            {currentWorkCount?.map((item,index) => (
                <>
                <div className="box2col form2col">
                <InputField labelName={`Current Designation-${index+1}`} placeholder={'Enter Current Designation'} notImp={true}  name={"currentDesignation"} onChange={(e) => handleCurrentWorkChange(e,index)} value={currentWork?.length > 0 && currentWork[index]?.currentDesignation}/>
                <InputField labelName={`Current Company-${index+1}`} placeholder={'Enter Current Company'} notImp={true}  name={"currentCompany"} onChange={(e) => handleCurrentWorkChange(e,index)} value={currentWork?.length > 0 && currentWork[index]?.currentCompany}/>
            </div>
            <div className="width50 mt-20">
                <InputField labelName={`Current Location -${index+1}`} placeholder={'Enter Current Location'} notImp={true}  name={"currentLocation"} onChange={(e) => handleCurrentWorkChange(e,index)} value={currentWork?.length > 0 && currentWork[index]?.currentLocation}/>
            </div>
            </>
            )) }
            <div className="buttonWrapForm">
                <span className="bluetextBtn mt-10" onClick={() => {setCurrentWorkCount(prev => [...prev,0]); setCurrentWork(prev => [...prev,currentWorkTemplate])}} >
                    Add Current Work
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.00016 14.6666C11.6668 14.6666 14.6668 11.6666 14.6668 7.99992C14.6668 4.33325 11.6668 1.33325 8.00016 1.33325C4.3335 1.33325 1.3335 4.33325 1.3335 7.99992C1.3335 11.6666 4.3335 14.6666 8.00016 14.6666Z" stroke="#0052CC" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M5.3335 8H10.6668" stroke="#0052CC" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M8 10.6666V5.33325" stroke="#0052CC" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                </span>
            </div>
           {peviousWorkCount?.map((item,index) => (
            <>
            <div className="box2col form2col mt-40">
            <InputField labelName={`Previous Designation -${index+1}`} placeholder={'Enter Previous Designation - 1'} notImp={true}  name={"previousDesignation"} onChange={(e) => handlePreviousWorkChange(e,index)} value={previousWork?.length > 0 && previousWork[index]?.previousDesignation}/>
            <InputField labelName={`Previous Company -${index+1}`} placeholder={'Enter Previous Company - 1'} notImp={true}  name={"previousCompany"} onChange={(e) => handlePreviousWorkChange(e,index)} value={previousWork?.length > 0 && previousWork[index]?.previousCompany}/>
        </div>
        <div className="width50 mt-20">
            <InputField labelName={`Previous Location -${index+1}`} placeholder={'Enter Previous Location -1'} notImp={true}  name={"previousLocation"} onChange={(e) => handlePreviousWorkChange(e,index)} value={previousWork?.length > 0 && previousWork[index]?.previousLocation}/>
            {/* <InputField labelName={'Previous Company - 2'} placeholder={'Enter Previous Company - 2'} notImp={true}  name={"previousCompany2"} onChange={onChange}/> */}
        </div>
          </>
           )) }
           <div className="buttonWrapForm">
            <span className="bluetextBtn mt-10" onClick={() => {setPrevioustWorkCount(prev => [...prev,0]); setPreviousWork(prev => [...prev,previousWorkTemplate])}}>
                Add Previous Work
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.00016 14.6666C11.6668 14.6666 14.6668 11.6666 14.6668 7.99992C14.6668 4.33325 11.6668 1.33325 8.00016 1.33325C4.3335 1.33325 1.3335 4.33325 1.3335 7.99992C1.3335 11.6666 4.3335 14.6666 8.00016 14.6666Z" stroke="#0052CC" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M5.3335 8H10.6668" stroke="#0052CC" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8 10.6666V5.33325" stroke="#0052CC" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
            </span>
           </div>
        </div>
    )
}

export default MasterForm1

