import React from 'react'
import './ButtonStyle.css';

const Button = ({className,iconName,text,onClick,disabled}) => {
  return (
    <button className={`button ${className}`} onClick={onClick} disabled={disabled}>
        {iconName && <img src={`/assets/svg/${iconName}.svg`} alt="" />}
        {text}
    </button>
  )
}
 
export default Button