import React, { useRef} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '../../Components/Button/Button'
import { getAllMasters } from '../../reducer/masterSlice'
import { useDispatch} from "react-redux";
import './HeaderStyle.css'
import'./SearchBoxStyle2.css'

const Header = ({setShowAddDetails, setShowAddDetails2, setMessage, message, setSearchTearm, setShowLogout,setTotalMentors}) => {
const dispatch = useDispatch();
const location = useLocation();
const navigate = useNavigate();
const inputEl= useRef("") ;

const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // 👇 Get input value
      const obj = {
        focusAreas:"",
        currentLocation:"",
        engagement: "",
        search:message,
        pageNo:1
      };
      setTotalMentors([])
      setSearchTearm(message)
      dispatch(getAllMasters(obj));

    }
};

return (
    <header>
        <div className={`headerWrap flexbox ${location.pathname === "/login" && 'secondHeader'}`}>
            <img src="/assets/svg/MastersUnionLogo.svg" alt="" className='logo'/>
           {( location.pathname !== '/login' && location.pathname.split("/")[1] !== "aboutMaster") &&  <div className="flexbox headerRight">
                {/* <SearchBox term={term} searchKeyword={searchKeyword} inputEl={inputEl} getSearchTerm={getSearchTerm}/> */}
                <div className="searchWrap flexbox">
                    <img src="/assets/svg/SearchIcon.svg" alt="" className="searchIcon" />
                    <input type="text"  placeholder= "Search....." className='searcBox' value={message}  onChange={handleChange} onKeyDown={handleKeyDown} ref={inputEl} />
                </div>
                <span className="divider"></span>
                <Button className={'blueBtn'} iconName={'AddIcon'} text={'Bulk Upload'} onClick={()=>{setShowAddDetails2(true)}}/>
                <Button className={'blueBtn'} iconName={'AddIcon'} text={'Add Master Details'} onClick={()=>{setShowAddDetails(true)}}/>
                <Button className={'BlueBorderButton'} text={'Logout'} onClick={()=>{setShowLogout(true)}}/>
            </div>}
        </div>
        {location.pathname !== '/' && location.pathname !== '/login'&&
        <div className="headerWrap flexbox">
            <div className="Navleft flexbox gap15 pointer" onClick={()=>(navigate("/"))}>
                <img src="/assets/svg/ArrowLeft.svg" alt=""  />
                <p className="text-14 text-black3">Master Profile</p>
            </div>
            <Button className={'blueBtn'} iconName={'EditIcon'} text={'Edit Profile '} onClick={()=>{setShowAddDetails(true)}}/>
        </div>
        }
    </header>
  )
}

export default Header