import React, { useState } from 'react'
import CustomDropdown from '../../CustomDropdown/CustomDropdown'
import InputField from '../../InputField/InputField'

const MasterForm2 = ({isActive, onChange, educationDetails, otherEducationCount,setOtherEducationCount,handleOtherEducation,setOtherEducation,otherEducation}) => {
const DropdownValue = ['First', 'Second','Third','Fourth'];

const otherEducationTemplate={
    otherCollege:" ",
    degree:" ",
    specialization:" ",
    year:" ",
}
const [dropdownToggler, setDropdownToggler] = useState(false);    
// console.log("xoxoxox", educationDetails);

    return (
        <div className={`formRight ${isActive && 'active'}`}>
            <div className="box2col form2col mt-20">
                <InputField labelName={'Undergrad College'} placeholder={'Enter Undergrad College'} notImp={true} name={"undergradCollege"} onChange={onChange} value={educationDetails?.undergradCollege}/>
                <InputField labelName={'Undergrad Degree'} placeholder={'Enter Undergrad Degree'} notImp={true} name={"undergradDegree"} onChange={onChange} value={educationDetails?.undergradDegree}/>
            </div>
            <div className="box2col form2col mt-20 adjustMentMulti">
                {/* {console.log(educationDetails?.undergradSpecialization,'pp')} */}
                <InputField labelName={'Undergrad Specialization'} placeholder={'Enter Undergrad Specialization'} notImp={true} name={"undergradSpecialization"} onChange={onChange} value={educationDetails?.undergradSpecialization}/>
                {/* <CustomDropdown label={'Undergrad Year'} className={'filterDrop'} dropName={'Enter Undergrad Year'} options={DropdownValue}   data={educationDetails?.undergradYear} value={educationDetails?.undergradSpecialization} isActive={dropdownToggler} setIsActive={setDropdownToggler} onChange={onChange} name={"undergradYear"}/> */}
                <InputField labelName={'Undergrad Year'} placeholder={'Enter Undergrad Year'} notImp={true} name={"undergradYear"} onChange={onChange} value={educationDetails?.undergradYear}/>

            </div>
            <div className="box2col form2col mt-20">
                <InputField labelName={'Postgrad College'} placeholder={'Enter Postgrad College'} notImp={true} name={"postgradCollege"} onChange={onChange} value={educationDetails?.postgradCollege}/>
                <InputField labelName={'Postgrad Degree'} placeholder={'Enter Postgrad Degree'} notImp={true} name={"postgradDegree"} onChange={onChange}  value={educationDetails?.postgradDegree}/>
            </div>
            <div className="box2col form2col mt-20">
                <InputField labelName={'Postgrad Specialization'} placeholder={'Enter Postgrad Specialization'} notImp={true} name={"postgradSpecialization"} onChange={onChange} value={educationDetails?.postgradSpecialization}/>
                <InputField labelName={'Postgrad Year'} placeholder={'Enter Postgrad Year'} notImp={true} name={"postgradYear"} onChange={onChange} value={educationDetails?.postgradYear}/>
            </div>
            <div className="box2col form2col mt-40">
                <InputField labelName={'PhD College'} placeholder={'Enter PhD College'} notImp={true} name={"phdCollege"} onChange={onChange} value={educationDetails?.phdCollege}/>
                <InputField labelName={'PhD Area'} placeholder={'Enter PhD Area'} notImp={true} name={"phdArea"} onChange={onChange} value={educationDetails?.phdArea}/>
            </div>
            <div className="mt-20 width50">
                <InputField labelName={'PhD Year'} placeholder={'Enter PhD Year'} notImp={true} name={"phdYear"} onChange={onChange} value={educationDetails?.phdYear}/>
            </div>
            {otherEducationCount?.map((item,index) =>(
                <>
             <div className="box2col form2col mt-40">
                {/* {console.log(otherEducation?.otherCollege,"OTHER COPLLEGE")} */}
             <InputField labelName={`Other College -${index+1}`} placeholder={'Enter College'} notImp={true} name={"otherCollege"} onChange={(e) => handleOtherEducation(e,index)} value={otherEducation?.length > 0 && otherEducation[index]?.otherCollege}/>
             <InputField labelName={'Degree'} placeholder={'Enter Degree'} notImp={true} name={"degree"} onChange={(e) => handleOtherEducation(e,index)} value={otherEducation?.length > 0 && otherEducation[index]?.degree}/>
         </div>
         <div className="box2col form2col mt-20">
             <InputField labelName={'Specialization'} placeholder={'Enter Specialization'} notImp={true} name={"specialization"} onChange={(e) => handleOtherEducation(e,index)}  value={otherEducation?.length > 0 && otherEducation[index]?.specialization}/>
             <InputField labelName={'Year'} placeholder={'Enter Year'} notImp={true}  name={"year"} onChange={(e) => handleOtherEducation(e,index)} value={otherEducation?.length > 0 && otherEducation[index]?.year}/>
         </div>
            </>
            ))}
             <div className="buttonWrapForm">
            <span className="bluetextBtn mt-10" onClick={() => {setOtherEducationCount(prev => [...prev,0]); setOtherEducation(prev => [...prev,otherEducationTemplate])}} >
                    Add Other
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.00016 14.6666C11.6668 14.6666 14.6668 11.6666 14.6668 7.99992C14.6668 4.33325 11.6668 1.33325 8.00016 1.33325C4.3335 1.33325 1.3335 4.33325 1.3335 7.99992C1.3335 11.6666 4.3335 14.6666 8.00016 14.6666Z" stroke="#0052CC" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M5.3335 8H10.6668" stroke="#0052CC" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8 10.6666V5.33325" stroke="#0052CC" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
            </span>
            </div>
        </div>
    )
}

export default MasterForm2