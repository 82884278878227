import React from 'react'
import './FilterSlideStyle.css';
import CustomDropdown from '../../Components/CustomDropdown/CustomDropdown';
import { useState } from 'react';
import Button from '../../Components/Button/Button';
import {getAllMasters} from '../../reducer/masterSlice'
import { useDispatch} from 'react-redux';
import InputField from '../InputField/InputField'

const FilterSlide = ({showFilter,setshowFilter,filter,setFilter, setTotalMentors,}) => {
const DropdownValue = ['Finance','Sales and Marketing',
  'Human Resources Management (HRM)', 
  'Operations Management',
  'Entrepreneurship',
  'Business Analytics and Data Science',
  'Strategy and Consulting',
  'Information Technology',
  'Banking and Investments ',
  'Supply Chain',
  'Product Management',
  'Leadership ',
   'PE/VC',
   'Communications',
   'See All'
]
const DropdownValue2 = ['Teaching', 'Guest sessions',"Mentorship", "Others","See All"];
const [active, setActive] = useState(false);
const [active2, setActive2] = useState(false);
const [active3, setActive3] = useState(false);
const dispatch = useDispatch();
const infoChange =(e)=>{
  const { name, value } = e.target;
  if(value != "See All"){
  setFilter(prev =>({...prev,[name]:value}))
  }else{
    setFilter(prev =>({...prev,[name]:''}))
  }
}

const handleClick =()=>{
  const obj ={
    focusAreas:filter.focusAreas,
    engagement:filter.engagement,
    currentLocation:filter.currentLocation,
    search:"",
    pageNo:""
  }
  setTotalMentors([])
  dispatch(getAllMasters(obj));
  setshowFilter(false)
}

const handleClickReset=()=>{
  window.location.reload()
}
// console.log(filter);
  return (
    <div className={`sideFilterBoxWrapper ${showFilter ? 'active': ''}`}>
      <div className="sideFilterBox">
          <div className="popupHeader flexbox">
              <h4 className="popupHeading">Profile Details</h4>
              <span onClick={()=>setshowFilter(false)} className="close"><img src="/assets/svg/close.svg" alt=""  /></span>
          </div>
          <div className="sideMenuBody">
              <CustomDropdown label={'Focus Areas*'} isimp={'isimp'} className={'filterDrop'} dropName={filter?.focusAreas!=="" ? filter?.focusAreas:"See All"} options={DropdownValue} isActive={active} setIsActive={setActive} data={filter?.focusAreas} onChange={(e) => infoChange(e)} name={"focusAreas"}/>
              <CustomDropdown label={'Engagement'} isimp={'isimp'} className={'filterDrop'} dropName={filter?.engagement!=="" ? filter?.engagement:"See All"} options={DropdownValue2} isActive={active2} setIsActive={setActive2}   data={filter?.engagement} onChange={(e) => infoChange(e)} name={"engagement"}/>
              <br></br>
              <InputField labelName={'Current Location'} className={'filterDrop'} placeholder={'Enter Current Location'} notImp={false} name={"currentLocation"} onChange={(e) => infoChange(e)} value={filter?.currentLocation}/>
          </div>
          <div className="sideMenuFooter flexbox">
            <Button className={'GreyFillButton'} text={'Reset'} onClick={()=>handleClickReset()}/>
            <Button className={'BlueFillButton'} text={'Show Result'} onClick={()=>handleClick()}/>
          </div>
      </div>
    </div>
  )
}

export default FilterSlide