import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";

let token;
let setTokenValues = () => {
  token = localStorage.getItem("token");
};

const masterRoute = "/api/crm/";


export const getAllMasters = createAsyncThunk(
  "masters/getAllMasters",
  async ({focusAreas,currentLocation,engagement, search, pageNo},thunkAPI) => {
    // console.log(focusAreas,currentLocation,engagement,"1234567890-");
    // console.log(currentLocation,"234567890")
     
    try {
      // ?focusAreas=${focusAreas}&currentLocation=${currentLocation}&engagement=${engagement}&searchParams=${search}
      setTokenValues();
      const response = await fetch(
       ( environment.baseURL + masterRoute + `getAllMasters?focusAreas=${focusAreas}&currentLocation=${currentLocation}&engagement=${engagement}&pageSize=10&pageNo=${pageNo}&searchParams=${search}`),
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.statusText === "Unauthorized") {
        localStorage.clear();
        window.location.reload();
      }
      let data = await response.json();

      // console.log(data.Data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getMasterDetails = createAsyncThunk(
  "master/getMasterDetails",
  async (masterId, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + masterRoute +"getMasterDetails/"+ masterId,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }  
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const loginUser = createAsyncThunk(
  "master/loginUser",
  async ({ email, password }, thunkAPI) => {
    try {
      const payload = {
        email: email,
        password: password,
      };
      const response = await fetch(
        `${environment.baseURL}${masterRoute}login`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      // console.log("response", data);
      if (response.status === 200) {
        localStorage.setItem("token", data.Token);
        localStorage.setItem("isLoggedIn", true);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const addMasterDetail = createAsyncThunk(
  "mentee/addMasterDetail",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + masterRoute + "addMasterDetail",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          }, 
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateMasterDetail = createAsyncThunk(
  "mentee/updateMasterDetail",
  async ({masterId,obj}, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + masterRoute + "updateMasterDetail/"+ masterId,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);


export const addEngagement = createAsyncThunk(
  "mentee/addEngagement",
  async ({masterId,obj}, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + masterRoute + "addEngagement/" + masterId,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const addAdditionalNote = createAsyncThunk(
  "mentee/addAdditionalNote",
  async ({masterId,obj}, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + masterRoute + "addAdditionalNote/" + masterId,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);



export const updateEngagement = createAsyncThunk(
  "mentee/addEngagement",
  async ({id,obj,masterId}, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + masterRoute + "updateEngagement/" + id + "/" + masterId ,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);


export const addMastersCSV = createAsyncThunk(
  "mentee/addMastersCSV",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      let formData = new FormData();
      formData.append("file", obj.file);
      const response = await fetch(
        environment.baseURL +  masterRoute + "masterCsvUpload",
        {
          method: "POST",
          headers: {
            // Accept: "application/json",
            // "Content-Type": "application/json",
            Authorization: token,
          },
          body: formData,
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const downloadSampleMasterCSV = createAsyncThunk(
  "mentee/downloadSampleMasterCSV",
  async (thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + masterRoute + "downloadSampleMasterCSV",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.text();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


export const masterDataCsv = createAsyncThunk(
  "master/masterDataCsv",
  async (thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + masterRoute +"masterDataCsv",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (response.status === 200) {
        const csvData = await response.text();

        // Create a Blob object from the CSV data
        const blob = new Blob([csvData], { type: 'text/csv' });

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create an <a> element to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.download = 'data.csv';

        // Trigger the download
        link.click();

        // Clean up by revoking the Blob URL
        window.URL.revokeObjectURL(url);

        return csvData;
        } else {
        return thunkAPI.rejectWithValue("error");
      }  
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const masterSlice = createSlice({
  name: "masters",
  initialState: {
    isFetching: false,
    isFetchingCsv: false,
    isMastersFetching: false,
    isSuccess: false,
    isSuccessCsv: false,
    isError: false,
    errorMessage: "",
    allMasters: [],
    mentorDetails: [],
    csvData: [],
    emasterId:"",
    token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.isMastersFetching = false;
      state.emasterId = "";
      return state;
    },
  },
  extraReducers: {
    [getAllMasters.fulfilled]: (state, { payload }) => {
      state.isMastersFetching = false;
      state.allMasters = payload;
      // console.log("fullfilllll",state.allMasters);
      return state;
    },
    [getAllMasters.rejected]: (state, { payload }) => {
      state.isMastersFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAllMasters.pending]: (state) => {
      state.isMastersFetching = true;
    },
    [getMasterDetails.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.mentorDetails = payload;
      return state;
    },
    [getMasterDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getMasterDetails.pending]: (state) => {
      state.isFetching = true;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      state.token = payload?.Token;
      state.isFetching = false;
      state.isSuccess = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      return state;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [loginUser.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [addMasterDetail.fulfilled]: (state, { payload }) => {
      // console.log("printing payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      return state;
    },
    [addMasterDetail.rejected]: (state, { payload }) => {
      state.emasterId = payload?.Data?.id
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [addMasterDetail.pending]: (state) => {
      state.isFetching = true;
    },
    [addMastersCSV.fulfilled]: (state, { payload }) => {
      // console.log("printing payload", payload);
      state.errorMessage = payload?.Message || "Something Went Wrong";
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [addMastersCSV.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [addMastersCSV.pending]: (state) => {
      state.isFetching = true;
    },
    [downloadSampleMasterCSV.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.csvData = payload;
      return state;
    },
    [downloadSampleMasterCSV.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [downloadSampleMasterCSV.pending]: (state) => {
      state.isFetching = true;
    },
    [updateMasterDetail.fulfilled]: (state, { payload }) => {
      // console.log("printing payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      return state;
    },
    [updateMasterDetail.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [updateMasterDetail.pending]: (state) => {
      state.isFetching = true;
    },
    [addAdditionalNote.fulfilled]: (state, { payload }) => {
      // console.log("printing payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [addAdditionalNote.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [addAdditionalNote.pending]: (state) => {
      state.isFetching = true;
    },
    [addEngagement.fulfilled]: (state, { payload }) => {
      // console.log("printing payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [addEngagement.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [addEngagement.pending]: (state) => {
      state.isFetching = true;
    },
    [updateEngagement.fulfilled]: (state, { payload }) => {
      // console.log("printing payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [updateEngagement.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [updateEngagement.pending]: (state) => {
      state.isFetching = true;
    },
    [masterDataCsv.fulfilled]: (state, { payload }) => {
      state.isFetchingCsv = false;
      state.isSuccessCsv = true;
      return state;
    },
    [masterDataCsv.rejected]: (state, { payload }) => {
      state.isFetchingCsv = false;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [masterDataCsv.pending]: (state) => {
      state.isFetchingCsv = true;
    },
  },
});

export const { clearState } = masterSlice.actions;

export const masterSliceData = (state) => state.masters;
