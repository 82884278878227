import React from 'react'
import './LogoutPopupStyle.css'
import Button from '../../Button/Button'

const LogoutPopup = ({ showLogout,setShowLogout }) => {

    const logout=()=>{
        localStorage.clear();
        window.location.reload();
        console.log("logout");
    }
    return (
        <div className={`popupWrapper  ${showLogout ? 'active' : ""}`}>
            <div className="popupContainer Logoutpopupbody">
                <span className="closebtn" onClick={()=>{setShowLogout(false)}}><img src="/assets/svg/close.svg" alt="" /></span>
                <div className="popupBody">
                    <p className='text14'>Do you wish to logout?</p>
                </div>
                <div className='footerButton'>
                    <Button className={'RedFillButton'} text={`Logout`}  onClick={()=>logout()}/>
                </div>
            </div>
        </div>
    )
}

export default LogoutPopup