import { useState } from "react";
import { Route, Routes, useLocation } from "react-router";
import "./App.css";
import FilterSlide from "./Components/FilterSlide/FilterSlide";
import CashDetailPopup from "./Components/Popups/CashDetailPopup/CashDetailPopup";
import MastersDeatailPopup from "./Components/Popups/MastersDeatailPopup/MastersDeatailPopup";
import Header from "./Layout/Header/Header";
import Home from "./Pages/Home/Home";
import MasterDetail from "./Pages/MasterDetail/MasterDetail";
import LoginPage from "./Pages/authentication/LoginPage";
import { PrivateRoute } from "./helpers/PrivateRoute";
import CSVPopup from "./Components/Popups/CSVPopup/CSVPopup";

function App() {
  const location = useLocation();
  const [showFilter, setshowFilter] = useState(false);
  const [showAddDetails, setShowAddDetails] = useState(false);
  const [showAddDetails2, setShowAddDetails2] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const [searchTerm, setSearchTearm] = useState("");
  const [message, setMessage] = useState('');
  const [filter,setFilter]=useState({
    focusAreas:"",
    currentLocation:"",
    engagement:"",
  })

  const [totalMentors, setTotalMentors] = useState([]);

// const searchHandler = () =>{}
  // const searchHandler = (searchTerm) => {
  //   setSearchTearm(searchTerm);
  //   if (searchTerm !== "") {
  //     const newMasterList = masterList.filter((masterList) => {
  //      return Object.values(masterList)
  //         .join(" ")
  //         .toLowerCase()
  //         .includes(searchTerm.toLowerCase());
  //     });
  //     // console.log(newMasterList,"ttttttttttt");
  //     setSearchResult(newMasterList)
  //   }else{
  //     setSearchResult(masterList)
  //   }
  // };

  return (
    <>
    <CSVPopup 
    setShowAddDetails2={setShowAddDetails2}
    showAddDetails2={showAddDetails2}
    
    />
    <div className="mainContainer">
      <FilterSlide showFilter={showFilter} setshowFilter={setshowFilter} 
      filter={filter} setFilter={setFilter}
      setTotalMentors={setTotalMentors} totalMentors={totalMentors}
      />
      < Header
        setShowAddDetails={setShowAddDetails}
        setShowAddDetails2={setShowAddDetails2}
        message={message}
        setMessage={setMessage}
        setSearchTearm={setSearchTearm}
        filter={filter}
        setShowLogout={setShowLogout}
        setTotalMentors={setTotalMentors}

      />
      <div
        className={`mainWrapper ${location.pathname === "/" && "singleHeader" || location.pathname === "/login" && 'fullHeight'}`}
      >
        <Routes>
          <Route path="/login" exact element={<LoginPage />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route
              exact
              path="/"
              element={
                <Home
                  setshowFilter={setshowFilter}
                  showAddDetails={showAddDetails}
                  setShowAddDetails={setShowAddDetails}
                  filter={filter}
                  searchTerm={searchTerm}
                  message={message}
                  setShowLogout={setShowLogout}
                  showLogout={showLogout}
                  setTotalMentors={setTotalMentors} totalMentors={totalMentors}
                  
                />
              }
              />
            <Route
              exact
              path="/aboutMaster/:id"
              element={
                <MasterDetail
                  showAddDetails={showAddDetails}
                  setShowAddDetails={setShowAddDetails}
                />
              }
            />
          </Route>
        </Routes>
      </div>
    </div>
    </>
  );
}

export default App;
