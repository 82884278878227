import React, { useState, useEffect } from "react";
import "./addNotePopupStyle.css";
import Button from "../../Button/Button";
import { useDispatch, useSelector } from 'react-redux';
import { clearState,addAdditionalNote, masterSliceData} from "../../../reducer/masterSlice";
import PageLoader from '../../PageLoader/PageLoader';

const AddNotePopup = ({ setShowAddNote, showAddNote, id }) => {
  const [note,setNotes] = useState('');
  const [isNoteEmpty, setIsNoteEmpty] = useState(false)
  const dispatch = useDispatch();
  const { isFetching,isSuccess,isError } = useSelector(masterSliceData);

  const handleSubmit =()=>{
    if(note !== ''){
   const obj={
    note : note,
    date: new Date()
    }
    
    const object = {
      masterId: id,
      obj
    }
   console.log(object);
  //  setShowAddNote(false);
   setIsNoteEmpty(false);
   dispatch(addAdditionalNote(object)) 

  }else{
   console.log("pleasse fill thr note");
   setIsNoteEmpty(true);
  }
  }

  const handleTextareaFocus =()=>{
    setIsNoteEmpty(false);
  }
  
  useEffect(() => {

    if (isSuccess) {
      setShowAddNote(false);
      dispatch(clearState());
      setNotes('');
      console.log("runnnn");
    }
  }, [isSuccess]);

  return (
    <>
    {isFetching  && <PageLoader/>}
      <div className={`popupWrapper ${showAddNote && "active"}`}>
        <div className="popupContainer addNotesPopupmain">
          <div className="popupHeader">
            <p className="popupHeading">Additional Notes</p>   

            <span className="close">
              <img
                src="/assets/svg/close.svg" 
                alt=""
                onClick={() => setShowAddNote(false)}
              />
            </span>
          </div>

          <div className="popupBody textareabody">
            <textarea className="textarea" name="" rows="8" cols="50" placeholder="Type a note ...."
            onChange={(e)=>setNotes(e.target.value)}
            onFocus={handleTextareaFocus}
            ></textarea>
             {isNoteEmpty &&  <div className="innertextarea"><p className="FormError">Please fill the note field.</p></div>}
          </div>

          <div className="popupFooter mt-20">
            <div className="buttonWrapper">
              <Button className={"GreyFillButton"} text={"Cancel"}   onClick={() => setShowAddNote(false)}/>
              <Button className={"BlueFillButton"} text={"Submit"}   onClick={()=> handleSubmit() }/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNotePopup;
