import React, { useState, useEffect } from 'react'
import Button from '../../Button/Button';
import CustomDropdown from '../../CustomDropdown/CustomDropdown';
import InputField from '../../InputField/InputField';
import CustomRadioButton from '../../CustomRadioButton/CustomRadioButton';
import CustomUploadBox from '../../CustomUploadBox/CustomUploadBox';
import './EditDetailPopupStyle.css';
import { useDispatch, useSelector } from 'react-redux';
import { clearState,updateEngagement, masterSliceData, getMasterDetails } from "../../../reducer/masterSlice";
import PageLoader from '../../PageLoader/PageLoader';

const CashDetailPopup = ({editEngagement,setEditEngagement,session,id}) => {
    const [dropdownToggler, setDropdownToggler] = useState(false); 
    const [dropdownToggler2, setDropdownToggler2] = useState(false); 
    const [dropdownToggler3, setDropdownToggler3] = useState(false); 
    const [dropdownToggler4, setDropdownToggler4] = useState(false); 
    const [dropdownToggler5, setDropdownToggler5] = useState(false); 
    const [dropdownToggler6, setDropdownToggler6] = useState(false); 
    const [dropdownToggler7, setDropdownToggler7] = useState(false); 

    const DropdownValue = ["UG","PGP","PGP Ex","Hybrid"];
    const DropdownValue2 = ["2020-21","2021-22","2021-23","2021-24", "2021-25","2021-26","2021-27","2023-27"];
    const DropdownValue3 = ["Term 1","Term 2","Term 3","Term 4", "Term 5","Term 6","Term 7","Term 8"];
    const DropdownValue4 = ["Core", "Elective", "Industry", "Skill"];
    const DropdownValue5 = ["1 Credit & 10 hours", "2 Credits & 20 hours","3 Credits & 30 Hours"];
    const DropdownValue6 = ['Finance','Sales and Marketing',
    'Human Resources Management (HRM)', 
    'Operations Management',
    'Entrepreneurship',
    'Business Analytics and Data Science',
    'Strategy and Consulting',
    'Information Technology',
    'Banking and Investments ',
    'Supply Chain',
    'Product Management',
    'Leadership ',
     'PE/VC',
     'Communications'];
    const DropdownValue7 = ["Virtual", "In-Person"];
    const DropdownValue8 = ["Fireside Chat", "MasterCamp", "Workshop", "Panel Discussion","Conference","Webinar","Rountable Discussion", "AMA", "Outbound Activity", "CXO Session", "Series C", "Podcast"];


    const dispatch = useDispatch();
    const {  allMasters,isFetching,isSuccess,isError } = useSelector(masterSliceData);
    
    useEffect(()=>{
        setEngagementInfo({
            sessionType2 : session.sessionType,
            programme : session.programme,
            cohort   : session.cohort,
            courseName : session.courseName,
            courseTerm : session.courseTerm,
            date: session.date,
            courseType : session.courseType,
            sessionFormate: session.sessionFormate,
            LOE : session.LOE,
            // invoice: session.invoice,
            courseOutline: session.courseOutline,
            teachingDate : session.teachingDate,
            creditsDuration: session.creditsDuration,
            studentNo : session.studentNo,
            classCoordinate : session.classCoordinate,
            overAllFeedback : session.overAllFeedback, 
            endDate: session.endDate, 
            deliveryMode:session.deliveryMode,
            courseOwner:session.courseOwner
          })

          setUrlPic({
            courseOutline:session.courseOutline,
            LOE:session.LOE,
            invoice:session.invoice
          })

          if(session?.engagementInvoiceCRMs?.length >0 ){
          setEngagementInvoice(session?.engagementInvoiceCRMs?.map((item,index)=>(
            {
              invoice:item?.invoice 
            }
          )))
          }

          if(session?.engagementInvoiceCRMs?.length >0 ){
            setInvoiceUrl(session?.engagementInvoiceCRMs?.map((item,index)=>(
              {
                invoice:item?.invoice 
              }
            )))
          }

    },[session])
    
const [engagementInfo,setEngagementInfo ]= useState({
    sessionType2 : session.sessionType,
    programme : "",
    cohort   : "",
    courseName : "",
    courseTerm : "",
    date: "", 
    courseType : "",  
    sessionFormate: "",   
    LOE : "",           
    // invoice: "", 
    courseOutline: "",    
    teachingDate : "",   
    creditsDuration: "",
    studentNo : "",             
    classCoordinate : "",
    overAllFeedback : "", 
    endDate: "", 
    deliveryMode:"",
    courseOwner: "",
  });

  const errorTemplate = {
    programme : "",
    cohort   : "",
    courseName : "",
    courseTerm : "",
    date: "",
    courseType : "",
    sessionFormate: "",
    LOE : "",
    // invoice: "",
    courseOutline: "",
    teachingDate : "",
    creditsDuration: "",
    studentNo : "",
    classCoordinate : "",
    overAllFeedback : "", 
    endDate: "", 
    deliveryMode:"",
    courseOwner:"",
};
const [errorMsg,setErrorMsg] = useState(errorTemplate)  

    //////////////////////////add more/////////////////////

    const engagementInvoiceTemplate = {
      invoice: "",
  };

const [engagementInvoice,setEngagementInvoice ]= useState([{
  invoice:""
}])  


const [engagementInvoiceCount, setEngagementInvoiceCount] = useState([0]);
const[invoiceUrl,setInvoiceUrl]=useState([{
  invoice:""
}])
// console.log("engagement",engagementInvoice);
// console.log("invoiceUrl",invoiceUrl);


const handleUploadInvoice = (event,type, index) => {
  console.log("xxxo", event);
  if (
    event.target.files.length > 0 
  ) {
    const files = event.target.files[0];
    setLoad(true);

    const data = new FormData();
    data.append("file", files);
    data.append("upload_preset", "qlm4yp8h");
    data.append("cloud_name", "df491ehfg");

    fetch("https://api.cloudinary.com/v1_1/df491ehfg/raw/upload", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {


        if (res) {
          // setSelectedProfileKey(res.name);
          let arr = engagementInvoice;
          arr[index] = {
              [type]: res.secure_url
          }
          setEngagementInvoice([...arr])
          
          let arr2= invoiceUrl;
          arr2[index]={
              [type]:res.original_filename
          }
          setInvoiceUrl([...arr2])
          setLoad(false);
          // setUploadPopUp(false)
        } else {
          setLoad(false);
        }
      })
      .catch((err) => {
        setLoad(false);
        alert("image file not supported");
      });
  }
};  

const clearInvoicePic = (e,type,index) => {
  let arr = engagementInvoice;
          arr[index] = {
              [type]: ""
          }
          setEngagementInvoice([...arr])
          
          let arr2= invoiceUrl;
          arr2[index]={
              [type]:""
          }
          setInvoiceUrl([...arr2])
};  

// console.log("invoiceState", engagementInvoice);

///////////////////////////////////////////////////////////

  const [urlPic,setUrlPic]=useState({
    courseOutline:"",
    LOE:"",
    invoice:""
  })
  
  const infoChange =(e)=>{
      const { name, value } = e.target;
      setErrorMsg(errorTemplate)
      setEngagementInfo(prev =>({...prev,[name]: value, }))
      
    }

    const [load, setLoad] = useState(false);

    const handleUploadPic = (event,type) => {
        if (
          event.target.files.length > 0 
        ) {
          const files = event.target.files[0];
          setLoad(true);
    
          const data = new FormData();
          data.append("file", files);
          data.append("upload_preset", "qlm4yp8h");
          data.append("cloud_name", "df491ehfg");
    
          fetch("https://api.cloudinary.com/v1_1/df491ehfg/raw/upload", {
            method: "POST",
            body: data,
          })
            .then((res) => res.json())
            .then((res) => {
    
    
              if (res) {
                // setSelectedProfileKey(res.name);
                setEngagementInfo(prev => ({
                    ...prev,
                    [type]: res.secure_url
                }))
                setUrlPic(prev=>({
                    ...prev,
                    [type]:res.original_filename
                }))
                setLoad(false);
                // setUploadPopUp(false)
              } else {
                setLoad(false);
              }
            })
            .catch((err) => {
              setLoad(false);
              alert("image file not supported");
            });
        }
      };  

      const clearPic = (e,type) => {
        setEngagementInfo(prev => ({
            ...prev,
            [type]: ""
        }));
        setUrlPic(prev => ({
            ...prev,
            [type]: ""
        }))
      };  

    const handleOnClick =()=>{
       const obj = {
            sessionType : engagementInfo.sessionType2,
            programme : engagementInfo.programme,
            cohort   : engagementInfo.cohort,
            courseName : engagementInfo.courseName,
            courseTerm : engagementInfo.courseTerm,
            date: engagementInfo.date,
            courseType : engagementInfo.courseType,
            sessionFormate: engagementInfo.sessionFormate,
            LOE : engagementInfo.LOE,
            // invoice: engagementInfo.invoice,
            courseOutline: engagementInfo.courseOutline,
            teachingDate : engagementInfo.teachingDate,
            creditsDuration: engagementInfo.creditsDuration,
            studentNo : engagementInfo.studentNo,
            classCoordinate : engagementInfo.classCoordinate,
            overAllFeedback : engagementInfo.overAllFeedback, 
            endDate: engagementInfo.endDate,
            deliveryMode:engagementInfo.deliveryMode,
            courseOwner:engagementInfo.courseOwner,
            engagementInvoice:engagementInvoice

            
        }

        const obj2 = {
            obj,
            id : session.id,
            masterId: id
        }

        console.log("object---------------1", obj2);
        // console.log("object---------------2", id);
      dispatch(updateEngagement(obj2));  
    }  

    // console.log("urllll pic",engagementInfo );
    
   
    useEffect(() => {
        if (isError) {
          // toast.error(errorMessage);
        //   setIsShowError(true);
        //   setErrMessage(errorMessage);
          dispatch(clearState());
        }
    
        if (isSuccess) {
          dispatch(clearState());
          setEditEngagement(false);
          if(id)dispatch(getMasterDetails(id));
        }
      }, [isError, isSuccess]);

      const Validate =()=>{
            for (const key in engagementInfo) {
                if(key === "courseName") continue;
                if(key === "courseTerm") continue;
                if(key === "courseType") continue;
                if(key === "sessionFormate") continue;
                if(key === "teachingDate") continue;
                if(key === "courseOutline") continue;
                if(key === "invoice") continue;
                if(key === "LOE") continue;
                if(key === "teachingDate") continue;
                if(key === "creditsDuration") continue;
                if(key === "studentNo") continue;
                if(key === "classCoordinate") continue;
                if(key === "overAllFeedback") continue;
                if(key === "deliveryMode") continue;
                if(key === "courseOwner") continue;
                if(!engagementInfo[key]) {

                    setErrorMsg(prev => ({
                        ...prev, [key]: "Please fill this field"
                    }));
                    return true
                }
            }
     } 
// console.log("saved",engagementInfo);
// console.log("session",session);


    return (
      
        <>
        {(isFetching || load) && <PageLoader/>}
        <div className={`popupWrapper ${editEngagement && 'active'}`}> 
            <div className="popupContainer cashdetailPopup">
                <div className="popupHeader">
                    <p className="popupHeading">Master Engagement</p >
                    <span className="close"><img src="/assets/svg/close.svg" alt="" onClick={()=>setEditEngagement(false)}/></span>
                </div>
                <div className="popupBody">
                    {/* <span className="label">Primary Account Holder</span> */}
                    <div className="flexbox gap10">
                    <CustomRadioButton name={'sessionType2'} className={'mb-20 mt-10 radioWrap'} text={session?.sessionType} onChange={(e)=>{infoChange(e)}} value={'Teaching'} checked={ true}/>
                    {/* <CustomRadioButton name={'sessionType2'} className={'mb-20 mt-10 radioWrap'} text='Guest sessions' onChange={(e)=>{infoChange(e)}}  value={"Guest sessions"}  checked={engagementInfo.sessionType2 === 'Guest sessions' ? true :false}/>
                    <CustomRadioButton name={'sessionType2'} className={'mb-20 mt-10 radioWrap'} text='Mentorship' onChange={(e)=>{infoChange(e)}}  value={"Mentorship"}  checked={engagementInfo.sessionType2 === 'Mentorship' ? true :false}/>
                    <CustomRadioButton name={'sessionType2'} className={'mb-20 mt-10 radioWrap'} text='Others' onChange={(e)=>{infoChange(e)}}  value={"Others"}  checked={engagementInfo.sessionType2 === 'Others' ? true :false}/> */}
                    </div>
                   { engagementInfo.sessionType2 === 'Teaching' &&    
                   <>
                    <div className="classDivider mb-20"></div>
                    <div className="width50">
                        {/* <InputField className={'mb-20'} notImp={true} labelName={'Pricing'} placeholder={'Enter your Pricing'} /> */}
                    </div>
                    <div className="box2col form2col">
                        <CustomDropdown errorMessage={errorMsg["programme"]} label={'Programme'} className={'filterDrop'} dropName={'Select your Programme'}  options={DropdownValue} isActive={dropdownToggler} setIsActive={setDropdownToggler}  data={engagementInfo.programme}  onChange={(e) => {infoChange(e)}} name={"programme"}/>
                        <CustomDropdown errorMessage={errorMsg["cohort"]} label={'Cohort'} className={'filterDrop'} dropName={'Select your Cohort'} options={DropdownValue2}  isActive={dropdownToggler2} setIsActive={setDropdownToggler2} data={engagementInfo.cohort}  onChange={(e) => {infoChange(e)}} name={"cohort"}/>
                    </div>
                    <div className="box2col form2col mt-20">
                         <InputField   labelName={'Course Name'} placeholder={'Enter Course Name'} notImp={true} name={"courseName"}  value={engagementInfo.courseName} onChange={(e) => infoChange(e)}/>
                        <CustomDropdown  label={'Course Term'} className={'filterDrop'} dropName={'Select your Course Term'} options={DropdownValue3}  isActive={dropdownToggler3} setIsActive={setDropdownToggler3} data={engagementInfo.courseTerm}  onChange={(e) => {infoChange(e)}} name={"courseTerm"}/>
                    </div>
                    <div className="box2col form2col mt-20">
                        <CustomDropdown  label={'Course Type'} className={'filterDrop'} dropName={'Select your Course Type'} options={DropdownValue4}   isActive={dropdownToggler4} setIsActive={setDropdownToggler4} data={engagementInfo.courseType}  onChange={(e) => {infoChange(e)}} name={"courseType"}/>
                         <InputField    errorMessage={errorMsg["date"]}  labelName={'Engagement Start Dates'} type='date' placeholder={'Enter Teaching Dates'} notImp={true} value={engagementInfo.date}  onChange={(e) => {infoChange(e)}} name={"date"}/>
                    </div>
                    <div className="box2col form2col mt-20">
                         <InputField labelName={'No. of Students enrolled'} placeholder={'Enter No. of Students enrolled'} notImp={true}  value={engagementInfo?.studentNo}  onChange={(e) => {infoChange(e)}} name={"studentNo"}/>
                         <InputField labelName={'Class Coordinator/ T.A name'} placeholder={'Class Coordinator/ T.A name'} notImp={true}   value={engagementInfo?.classCoordinate}  onChange={(e) => {infoChange(e)}} name={"classCoordinate"}/>
                    </div>
                    <div className="box2col form2col mt-20 ">
                         <InputField labelName={'Overall Feedback (on 5)'} placeholder={'Enter No. of Students enrolled'} notImp={true} value={engagementInfo?.overAllFeedback}  onChange={(e) => {infoChange(e)}} name={"overAllFeedback"}/>
                         <CustomDropdown  label={'Delivery Mode'} className={'filterDrop'} dropName={'Select your Delivery Mode'} options={DropdownValue7}   isActive={dropdownToggler6} setIsActive={setDropdownToggler6} data={engagementInfo?.deliveryMode}  onChange={(e) => {infoChange(e)}} name={"deliveryMode"}/>
                    </div> 
                    <div className="box2col form2col mt-20 adjustMentMulti">
                    <InputField labelName={'Course Owner'} placeholder={'Enter name'} notImp={true} value={engagementInfo?.courseOwner}  onChange={(e) => {infoChange(e)}} name={"courseOwner"}/>
                         <InputField   errorMessage={errorMsg["endDate"]} labelName={'Engagement End Date'} placeholder={'Select your Date'} notImp={true} type='date' value={engagementInfo?.endDate}  onChange={(e) => {infoChange(e)}} name={"endDate"} />
                    </div> 
                    <div className="box2col form2col mt-20 Csboxuplodeimg">
                        <CustomDropdown label={'Credits & Duration'} className={'filterDrop'} dropName={'Select your Credits & Duration'} options={DropdownValue5}  isActive={dropdownToggler5} setIsActive={setDropdownToggler5} data={engagementInfo.creditsDuration}  onChange={(e) => {infoChange(e)}} name={"creditsDuration"}/>
                        <div className="boxLabel "> 
                            <span className="label">Course Outline</span>
                            <CustomUploadBox 
                             value={urlPic?.courseOutline}
                             onChange={(e)=>handleUploadPic(e,"courseOutline")}
                             onClick={(e)=>clearPic(e,"courseOutline")}
                            />
                        </div>
                    </div>
                    <div className="box2col form2col mt-20 adjustMentMulti twoInvoices">
                        <div className="boxLabel">
                            <span className="label">LOE</span>
                            <CustomUploadBox 
                             value={urlPic?.LOE}
                             onChange={(e)=>handleUploadPic(e,"LOE")}
                             onClick={(e)=>clearPic(e,"LOE")}
                            />
                        </div>
                       { engagementInvoice?.length > 0 &&  
                       engagementInvoice?.map((item,index)=>(
                        <div className="boxLabel">
                        <span className="label">Invoice {index +1}</span>

                        <CustomUploadBox 
                          value={invoiceUrl[index]?.invoice}
                          onChange={(e)=>handleUploadInvoice(e,"invoice",index)}
                          onClick={(e)=>clearInvoicePic(e,"invoice",index)}
                        />
                    </div>
                       ))  
                      }
                    </div>
                    </>
                    }

                   {engagementInfo.sessionType2 === 'Guest sessions' &&
                   <>
                    <div className="classDivider mb-20"></div>
                    <div className="width50">
                    </div>
                    <div className="box2col form2col">
                    <CustomDropdown errorMessage={errorMsg["programme"]} label={'Programme'} className={'filterDrop'} dropName={'Select your Programme'}  options={DropdownValue} isActive={dropdownToggler} setIsActive={setDropdownToggler}  data={engagementInfo.programme}  onChange={(e) => {infoChange(e)}} name={"programme"}/>
                    <CustomDropdown errorMessage={errorMsg["cohort"]} label={'Academic Year'} className={'filterDrop'} dropName={'Select your Academic Year'} options={DropdownValue2}  isActive={dropdownToggler2} setIsActive={setDropdownToggler2} data={engagementInfo.cohort}  onChange={(e) => {infoChange(e)}} name={"cohort"}/>
                    </div>
                    <div className="box2col form2col mt-20">
                    <InputField errorMessage={errorMsg["date"]} labelName={'Engagement Start Date'} placeholder={'Select your Date'} notImp={true} type='date' value={engagementInfo?.date}  onChange={(e) => {infoChange(e)}} name={"date"}/>

                    <CustomDropdown  label={'Course Term'} className={'filterDrop'} dropName={'Select your Course Term'} options={DropdownValue3}  isActive={dropdownToggler3} setIsActive={setDropdownToggler3} data={engagementInfo.courseTerm}  onChange={(e) => {infoChange(e)}} name={"courseTerm"}/>
                    </div>
                    <div className="box2col form2col mt-20">
                    <CustomDropdown  label={'Session Format'} className={'filterDrop'} dropName={'Select your Session Format'} options={DropdownValue8}  isActive={dropdownToggler7} setIsActive={setDropdownToggler7} data={engagementInfo.sessionFormate}  onChange={(e) => {infoChange(e)}} name={"sessionFormate"}/>
                    {/* <InputField labelName={'Session Format'} placeholder={'Enter Session Format'} notImp={true} data={engagementInfo.sessionFormate}  onChange={(e) => {infoChange(e)}} name={"sessionFormate"}/> */}
                    <InputField labelName={'Theme'} placeholder={'Enter Theme'} notImp={true} value={engagementInfo?.teachingDate}  onChange={(e) => {infoChange(e)}} name={"teachingDate"}/>
                    </div>
                    <div className="box2col form2col mt-20">
                    <InputField labelName={'Takeaways'} placeholder={'Enter Takeaways'} notImp={true} value={engagementInfo.studentNo}  onChange={(e) => {infoChange(e)}} name={"studentNo"}/>
                    <InputField labelName={'Class Registrations'} placeholder={'Enter Class Registrations'} notImp={true}   value={engagementInfo.classCoordinate}  onChange={(e) => {infoChange(e)}} name={"classCoordinate"}/>
                    </div>
                    <div className="box2col form2col mt-20">
                    <CustomDropdown  label={'Delivery Mode'} className={'filterDrop'} dropName={'Select your Delivery Mode'} options={DropdownValue7}   isActive={dropdownToggler6} setIsActive={setDropdownToggler6} data={engagementInfo?.deliveryMode}  onChange={(e) => {infoChange(e)}} name={"deliveryMode"}/>
                    <InputField errorMessage={errorMsg["endDate"]} labelName={'Engagement End Date'} placeholder={'Select your Date'} notImp={true} type='date' value={engagementInfo?.endDate}  onChange={(e) => {infoChange(e)}} name={"endDate"}/>
                    </div> 
                    <div className="box2col form2col mt-20 Csboxuplodeimg">
                            <InputField labelName={'Moderator'} placeholder={'Enter Moderator Name'} notImp={true} value={engagementInfo?.overAllFeedback} onChange={(e) => {infoChange(e)}} name={"overAllFeedback"}/>
                        <div className="boxLabel">
                            <span className="label">Script</span>
                            <CustomUploadBox 
                             value={urlPic?.courseOutline}
                             onChange={(e)=>handleUploadPic(e,"courseOutline")}
                             onClick={(e)=>clearPic(e,"courseOutline")}
                            />
                        </div>
                    </div>
                    <div className="box2col form2col mt-20 adjustMentMulti twoInvoices">
                        <div className="boxLabel">
                            <span className="label">LOE</span>
                            <CustomUploadBox 
                            value={urlPic?.LOE}
                            onChange={(e)=>handleUploadPic(e,"LOE")}
                            onClick={(e)=>clearPic(e,"LOE")}
                            />
                        </div>
                        { engagementInvoice?.length > 0 &&  
                       engagementInvoice?.map((item,index)=>(
                        <div className="boxLabel">
                        <span className="label">Invoice {index +1}</span>

                        <CustomUploadBox 
                          value={invoiceUrl[index]?.invoice}
                          onChange={(e)=>handleUploadInvoice(e,"invoice",index)}
                          onClick={(e)=>clearInvoicePic(e,"invoice",index)}
                        />
                    </div>
                       ))  
                      }
                    </div>
                    </>
                    } 

                   {  engagementInfo.sessionType2 === 'Mentorship' &&
                   <>
                    <div className="classDivider mb-20"></div>
                    <div className="box2col form2col">
                    <CustomDropdown errorMessage={errorMsg["programme"]}label={'Programme'} className={'filterDrop'} dropName={'Select your Programme'}  options={DropdownValue} isActive={dropdownToggler} setIsActive={setDropdownToggler}  data={engagementInfo.programme}  onChange={(e) => {infoChange(e)}} name={"programme"}/>
                    <CustomDropdown errorMessage={errorMsg["cohort"]} label={'Cohort'} className={'filterDrop'} dropName={'Select your Cohort'} options={DropdownValue2}  isActive={dropdownToggler2} setIsActive={setDropdownToggler2} data={engagementInfo.cohort}  onChange={(e) => {infoChange(e)}} name={"cohort"}/>
                    </div>
                    <div className="box2col form2col mt-20">
                    <InputField errorMessage={errorMsg["date"]} labelName={'Date of Onboarding'} placeholder={'Enter Date of Onboarding'} type='date' notImp={false} value={engagementInfo.date}  onChange={(e) => {infoChange(e)}} name={"date"}/>
                    <CustomDropdown label={'Focus Area/Expertise'} className={'filterDrop'} dropName={'Select your Focus Area/Expertise'} options={DropdownValue6}  isActive={dropdownToggler3} setIsActive={setDropdownToggler3} data={engagementInfo.courseTerm}  onChange={(e) => {infoChange(e)}} name={"courseTerm"}/>
                    </div>
                    <div className="box2col form2col mt-20">
                        
                    <CustomDropdown  label={'Delivery Mode'} className={'filterDrop'} dropName={'Select your Delivery Mode'} options={DropdownValue7}   isActive={dropdownToggler6} setIsActive={setDropdownToggler6} data={engagementInfo?.deliveryMode}  onChange={(e) => {infoChange(e)}} name={"deliveryMode"}/>
                        <InputField errorMessage={errorMsg["endDate"]} labelName={'Engagement End Date'} placeholder={'Select your Date'} notImp={false} type='date' value={engagementInfo?.endDate}  onChange={(e) => {infoChange(e)}} name={"endDate"}/>

                    </div>
                    <div className="box2col form2col mt-20 adjustMentMulti twoInvoices">
                        {/* <CustomDropdown label={'Course Type'} className={'filterDrop'} dropName={'Select your Course Type'} options={DropdownValue}  isActive={dropdownToggler4} setIsActive={setDropdownToggler4} data={engagementInfo.courseType}  onChange={(e) => {infoChange(e)}} name={"courseType"}/> */}
                         {/* <InputField labelName={'Teaching Dates'} placeholder={'Enter Teaching Dates'} notImp={true} type='date' data={engagementInfo?.teachingDate}  onChange={(e) => {infoChange(e)}} name={"teachingDate"}/> */}
                         <div className="boxLabel">
                            <span className="label">LOE</span>
                            <CustomUploadBox 
                             value={urlPic?.LOE}
                             onChange={(e)=>handleUploadPic(e,"LOE")}
                             onClick={(e)=>clearPic(e,"LOE")}
                            />
                        </div>
                        { engagementInvoice?.length > 0 &&  
                       engagementInvoice?.map((item,index)=>(
                        <div className="boxLabel">
                        <span className="label">Invoice {index +1}</span>

                        <CustomUploadBox 
                          value={invoiceUrl[index]?.invoice}
                          onChange={(e)=>handleUploadInvoice(e,"invoice",index)}
                          onClick={(e)=>clearInvoicePic(e,"invoice",index)}
                        />
                    </div>
                       ))  
                      }
                    </div>
                    </>
                    } 

                    {engagementInfo.sessionType2 === 'Others' &&
                   <>
                    <div className="classDivider mb-20"></div>    
                    <div className="box2col form2col">
                    <CustomDropdown errorMessage={errorMsg["programme"]} label={'Programme'} className={'filterDrop'} dropName={'Select your Programme'}  options={DropdownValue} isActive={dropdownToggler} setIsActive={setDropdownToggler}  data={engagementInfo.programme}  onChange={(e) => {infoChange(e)}} name={"programme"}/>
                    <CustomDropdown errorMessage={errorMsg["cohort"]} label={'Cohort'} className={'filterDrop'} dropName={'Select your Cohort'} options={DropdownValue2}  isActive={dropdownToggler2} setIsActive={setDropdownToggler2} data={engagementInfo.cohort}  onChange={(e) => {infoChange(e)}} name={"cohort"}/>
                    </div>
                    <div className="box2col form2col mt-20">
                    <InputField labelName={'Engagement Type'} placeholder={'Enter Engagement Type'} notImp={true} name={"courseName"}  value={engagementInfo.courseName} onChange={(e) => infoChange(e)}/>
                    <InputField errorMessage={errorMsg["date"]} labelName={'Engagement Start Date'} placeholder={'Select your Date'} notImp={true} type='date' value={engagementInfo.date}  onChange={(e) => {infoChange(e)}} name={"date"}/>
                    </div>
                    <div className="box2col form2col mt-20 adjustMentMulti"> 
                    <CustomDropdown  label={'Delivery Mode'} className={'filterDrop'} dropName={'Select your Delivery Mode'} options={DropdownValue7}   isActive={dropdownToggler6} setIsActive={setDropdownToggler6} data={engagementInfo?.deliveryMode}  onChange={(e) => {infoChange(e)}} name={"deliveryMode"}/>
                    <InputField errorMessage={errorMsg["endDate"]} labelName={'Engagement End Date'} placeholder={'Select your Date'} notImp={true} type='date' value={engagementInfo.date}  onChange={(e) => {infoChange(e)}} name={"endDate"}/>
                    </div>
                   </>
                    }

                </div>
                <div className="popupFooter mt-20">
                    <div className="buttonWrapper">
                        <Button className={'GreyFillButton'} text={'Cancel'} />
                        <Button className={'BlueFillButton'} text={'Submit'} onClick={()=>{  handleOnClick()}}/>      
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default CashDetailPopup