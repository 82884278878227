import React, { useState } from 'react'
import CustomDropdown from '../../CustomDropdown/CustomDropdown'
import CustomRadioButton from '../../CustomRadioButton/CustomRadioButton'
import CustomUploadBox from '../../CustomUploadBox/CustomUploadBox'
import InputField from '../../InputField/InputField'
import * as filestack from "filestack-js";
import PageLoader from "../../../Components/PageLoader/PageLoader"

const MasterForm3 = ({isActive,bankDetails,onChange,setPhotos,photos,infoBank,setPhotos2,photos2,accountHolderValue}) => {
 const [dropdownToggler, setDropdownToggler] = useState(false);    
const DropdownValue = ['Personal account', 'Representative account','Company account'];

  const [load, setLoad] = useState(false);

  // const handleUploadPic = (event,type) => {
  //   if (
  //     event.target.files.length > 0 
  //   ) {
  //     const files = event.target.files[0];
  //     setLoad(true);

  //     const data = new FormData();
  //     data.append("file", files);
  //     data.append("upload_preset", "qlm4yp8h");
  //     data.append("cloud_name", "df491ehfg");

  //     fetch("https://api.cloudinary.com/v1_1/df491ehfg/raw/upload", {
  //       method: "POST",
  //       body: data,
  //     })
  //       .then((res) => res.json())
  //       .then((res) => {


  //         if (res) {
  //           // setSelectedProfileKey(res.name);
  //           setPhotos(prev => ({
  //               ...prev,
  //               [type]: {
  //                   url: res.secure_url,
  //                   name: res.original_filename
  //               }
  //           }))
  //           setLoad(false);
  //           // setUploadPopUp(false)
  //         } else {
  //           setLoad(false);
  //         }
  //       })
  //       .catch((err) => {
  //         setLoad(false);
  //         alert("image file not supported");
  //       });
  //   }
  // };

  const handleUploadPic = (event,type) => {
    const client = filestack.init("AASYllbvHQ3u0A1GxbOvZz");

    client.picker({
      accept: ['image/*'],
      onUploadDone: (result) => {
        if (result?.filesFailed?.length > 0) {
          setLoad(false);
          alert("image file not supported");
        }
        if (result?.filesUploaded?.length > 0) {
       console.log("data", result?.filesUploaded);
       setPhotos(prev => ({
                      ...prev,
                      [type]: {
                          url: `https://filestack.mastersunion.org/${result?.filesUploaded[0]?.key}`,
                          name: result?.filesUploaded[0]?.filename
                      }
                  }))
                  setLoad(false);
        }
      },
    }).open();
  };

  const clearPic = (e,type) => {
    setPhotos(prev => ({
        ...prev,
        [type]: {url: "",name: ""}
    }))
  };


// console.log("photos",photos);
    return (
        <>
        {load && <PageLoader/>}
        <div className={`formRight ${isActive && 'active'}`}>
            {/* <span className="label">Account Holder</span> */}
            {/* {console.log(bankDetails[0]?.accountHolder,"PPPPPP____")} */}
            <div className="box2col form2col mt-20 adjustMentMulti">
                {/* <CustomRadioButton name={'accountHolder'} className={'mt-10 radioWrap'} text='Primary Account Holder' value={'Primary Account Holder'} checked={accountType} onChange={(e) => {infoBank(e,0); setAccountType(true)}}/> */}
                {/* <CustomRadioButton name={'accountHolder'} className={'mt-10 radioWrap'} text='Secondary Account Holder' value={'Secondary Account Holder'} checked={!accountType} onChange={(e) => {infoBank(e,0); setAccountType(false)}}/> */}
                {/* <CustomDropdown label={'Account Holder'}  className={'filterDrop'} dropName={'Enter Account Detail'} options={DropdownValueHolder}  data={bankDetails?.accountHolder}  isActive={dropdownToggler2} setIsActive={setDropdownToggler2} onChange={(e) => {infoBank(e)}} name={"accountHolder"}/> */}
                {/* <CustomDropdown  label={'Lead'}  className={'filterDrop'} dropName={'Select'} options={ DropdownLead}  data={bankDetails?.leads}  isActive={dropdownToggler3} setIsActive={setDropdownToggler3} onChange={(e) => {infoBank(e)}} name={"leads"}/> */}
            </div>
            
            <div className="box2col form2col mt-20">
              <InputField labelName={'Bank Name'} placeholder={'Enter Bank Name'} notImp={true} name={"bankName"}  value={bankDetails?.bankName} onChange={(e) => infoBank(e)}/>
              <InputField labelName={'Account Name'} placeholder={'Enter Account Name'} notImp={true} name={"accountName"} onChange={(e) => infoBank(e)} value={bankDetails?.accountName}/>
          </div>
          <div className="box2col form2col mt-20 adjustMentMulti">
              <InputField labelName={'Account Number'} placeholder={'Enter Account Number'} notImp={true} name={"accountNumber"} onChange={(e) => infoBank(e)} value={bankDetails?.accountNumber}/>
              <CustomDropdown label={'Account Type'} className={'filterDrop'} dropName={'Enter Account Number'} options={DropdownValue}  data={bankDetails?.accountType}  isActive={dropdownToggler} setIsActive={setDropdownToggler} onChange={(e) => infoBank(e)} name={"accountType"}/>
          </div>
          <div className="box2col form2col mt-20">
              <InputField labelName={'IFSC / Swift Code'} placeholder={'Enter IFSC / Swift Code'} notImp={true} name={"ifscCode"} onChange={(e) => infoBank(e)} value={bankDetails?.ifscCode}/>
              <InputField labelName={'Routing Number'} placeholder={'Enter Routing Number'} notImp={true} name={"routingNumber"} onChange={(e) => infoBank(e)} value={bankDetails?.routingNumber}/>
          </div>
          <div className="box2col form2col mt-20 adjustMentMulti">
              {/* <CustomDropdown  label={'Lead Source'}  className={'filterDrop'} dropName={'Select'} options={ DropdownLead}  data={bankDetails?.leads}  isActive={dropdownToggler3} setIsActive={setDropdownToggler3} onChange={(e) => {infoBank(e)}} name={"leads"}/> */}
               {/* <InputField labelName={'Swift Code'} placeholder={'Enter your Swift Code'} notImp={true} name={"swiftCode"} onChange={(e) => infoBank(e)} value={bankDetails?.swiftCode}/> */}
          </div>
          <div className="box2col form2col mt-20">
              <InputField labelName={'GST Number'} placeholder={'Enter your GST Number'} notImp={true} name={"gstNumber"} onChange={(e) => infoBank(e)} value={bankDetails?.gstNumber}/>
              <div className="boxLabel">
                  <span className="label">Cancelled Cheque</span>
                  <CustomUploadBox 
                  value={photos["chequeUrl"].name}
                  onChange={(e)=>handleUploadPic(e,"chequeUrl")}
                  onClick={(e)=>clearPic(e,"chequeUrl")}
                  />
              </div>
          </div>
          <div className="box2col form2col mt-20">
              <InputField labelName={'PAN Number'} placeholder={'Enter your PAN Number'} notImp={true} name={"panNumber"} onChange={(e) => infoBank(e)} value={bankDetails?.panNumber}/>
              <div className="boxLabel">
                  <span className="label">PAN Card Photo</span>
                  <CustomUploadBox onChange={(e)=>handleUploadPic(e,"panCardUrl")} 
                  value={photos["panCardUrl"].name} 
                  onClick={(e)=>clearPic(e,"panCardUrl")}
                  />
              </div>
          </div>
          <div className="box2col form2col mt-20">
              <InputField labelName={'Aadhar Number'} placeholder={'Enter your Aadhar Number'} notImp={true} name={"aadharNumber"} onChange={(e) => infoBank(e)} value={bankDetails?.aadharNumber}/>
              <div className="boxLabel">
                  <span className="label">Aadhar Card Photo</span>
                  <CustomUploadBox 
                  onChange={(e)=>handleUploadPic(e,"aadharCardUrl")}
                  value={photos["aadharCardUrl"].name} 
                  onClick={(e)=>clearPic(e,"aadharCardUrl")}
                  />
              </div>
          </div>
          <div className="width50 mt-20">
              <div className="boxLabel">
                  <span className="label">Other Photo</span>
                  <CustomUploadBox onChange={(e)=>handleUploadPic(e,"otherPhotoUrl")}
                  value={photos["otherPhotoUrl"].name} 
                  onClick={(e)=>clearPic(e,"otherPhotoUrl")}
                  />
              </div>
          </div>

        </div>
        </>
    )
}

export default MasterForm3