import React, { useState } from 'react'
import Button from '../../Button/Button';
import MasterForm1 from './MasterForm1';
import MasterForm2 from './MasterForm2';
import MasterForm3 from './MasterForm3';
import MasterForm4 from './MasterForm4';
import './MastersDeatailPopupStyle.css';
import { useDispatch, useSelector } from 'react-redux';
import { clearState,addMasterDetail, updateMasterDetail, getAllMasters, masterSliceData } from "../../../reducer/masterSlice";
import PageLoader from "../../PageLoader/PageLoader";
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';


const MastersDeatailPopup = ({showAddDetails, setShowAddDetails,
    otherEducation, setOtherEducation,
    previousWork, setPreviousWork,
    currentWork,  setCurrentWork,
    educationDetails, setEducationDetails,
    photos, setPhotos,
    photos2, setPhotos2,
    bankDetails, setBankDetails,
    personalInfo, setPersonalInfo,
    aliasState,setAliasState,
    otherEducationCount,setOtherEducationCount,
    currentWorkCount,setCurrentWorkCount,
    peviousWorkCount,setPrevioustWorkCount,object,id,
    currFomr,setCurrForm

}) => {
//   const navigate = useNavigate();
  const dispatch = useDispatch();
  const {pathname} = useLocation();
  const [accountHolderValue, setAccountHolderValue]=useState("");
  const [accountTypeValue, setAccountTypeValue]=useState("");

  const {  allMasters,isFetching,isError, isSuccess } = useSelector(masterSliceData);
    const errorTemplate = {
        title : "",
        firstName : "",
        lastName : "",
        // cohort : "",
        phoneNumber : "",
        email: "",
        linkedin : "",
        twitter : "",
        instagram: "",
        facebook : "",
        focusAreas: "",
        speciality: "",
        doB : "",
        state: "",
        residanceState:"",
        country : "",
        address : "",
        workCity : "",
        workState: "",
        workCountry : "",
        workAddress : "", 
    };
// const [currFomr, setCurrForm] = useState(1);
const [errorMsg,setErrorMsg] = useState(errorTemplate)

const infoChange =(e)=>{
    const { name, value } = e.target;
    setErrorMsg(errorTemplate)
    setPersonalInfo(prev =>({...prev,
        [name]: value, }))
}

const infoEducation =(e)=>{
    const { name, value } = e.target;
    setEducationDetails(prev =>({...prev,[name]: value, }))
}

const infoBank =(e)=>{
    const { name, value } = e.target;
    setErrorMsg(errorTemplate)
    setBankDetails(prev =>({...prev,[name]: value, }))

}

// const infoBank = (e,i) => {
//     const { name, value } = e.target;
//     let index = i;
//     if(name === "accountHolder"){
//         value === "Secondary Account Holder" ? index = 1 : index = 0;
//         setAccountHolderValue(value);
//     }
//     if(name === "accountType"){
//         value === "Representative account" ? index = 1 : index = 0;
//         setAccountTypeValue(value);
//     }
//     console.log(index,name,value,'index')
//     let arr = [...bankDetails];
//     let aj = {...arr[index],[name]:value}
//         arr[index]= aj
//         arr[index][name] = value;
//         setBankDetails([...arr]);
// }




const handleCurrentWorkChange = (e,i) => {
    const { name, value } = e.target;
    // console.log(i,'index')
    let arr = [...currentWork];
    let aj = {...arr[i],[name]:value}
        arr[i]= aj
        arr[i][name] = value;
    setCurrentWork([...arr]);
}

    const handlePreviousWorkChange = (e,i) => {
        const { name, value } = e.target;
        // console.log(i,'index')
        let arr = [...previousWork];
        let aj = {...arr[i],[name]:value}
        arr[i]= aj
        arr[i][name] = value;
        setPreviousWork([...arr]);
    }

    const handleOtherEducation = (e,i) => {
        const { name, value } = e.target;
        // console.log(i,'index',name)
        let arr = [...otherEducation];   
        let aj ={...arr[i],[name]:value}
      arr[i] =aj
        // arr[i][name] = value;
        setOtherEducation([...arr]);
    }

    const handleSubmit = () => {
       if( currFomr===4 && id){ 
        const obj= {
            masterId : id,
            obj : object
        }
        dispatch(updateMasterDetail(obj));
        console.log("subbmitupdate",obj);
    }
      
      if( currFomr===4 && pathname ==='/') {
        dispatch(addMasterDetail(object));
        // console.log("submitadd",object);
      }
      
    };

      useEffect(() => {
        if (isSuccess) {
          dispatch(clearState());
          
          setShowAddDetails(false);
          const obj = {    
            focusAreas: "",
            currentLocation:"", 
            engagement: "",
            search:"",
            pageNo:1
          };
          dispatch(getAllMasters(obj));
          
        }
      }, [isSuccess]);

     const Validate =()=>{
        if(currFomr === 1) {
            for (const key in personalInfo) {
                if(key === "instagram") continue;
                if(key === "title") continue;
                if(key === "facebook") continue;
                if(key === "twitter") continue;
                if(key === "linkedin") continue;
                if(key === "speciality") continue;
                if(key === "workCity") continue;
                if(key === "workCountry") continue;
                if(key === "workAddress") continue;
                if(key === "focusAreas") continue;
                if(key === "doB") continue;
                if(key === "state") continue;
                if(key === "country") continue;
                if(key === "address") continue;
                if(key === "residanceState") continue;
                if(key === "workState") continue;
                if(key === "leadSource") continue;
                if(!personalInfo[key]) {
                    setErrorMsg(prev => ({
                        ...prev, [key]: "Please fill this field"
                    }));
                    return true
                }
                if(key === "email" &&  !/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(personalInfo["email"])){
                        setErrorMsg(prev => ({
                            ...prev, [key]: "Please enter vaild Email"
                        }));
                        return true
                    
                } ;
                
                if(key === "phoneNumber" &&  personalInfo.phoneNumber.length !== 10){
                    setErrorMsg(prev => ({
                        ...prev, [key]: "Please enter vaild Phone no."
                    }));
                    return true
                
            } ;
// console.log(personalInfo.phoneNumber,"callll");  
            }
        }
     } 

  return (
    <div className={`popupWrapper ${showAddDetails ?'active':""}`}>
    <div className="popupContainer banktransferPopup masterDetailPopup">
        <div className="popupHeader">
            <p className="popupHeading">Add Master Details</p >
            <span className="close"><img src="/assets/svg/close.svg" alt=""  onClick={()=>{setShowAddDetails(false)}}/></span>
        </div>
        <div className="popupBody">
           <div className="flexbox popUpFlex">
                <div className="popUpLeft">
                    <div className={currFomr===1?"box2col docIcon gap10 current active":(personalInfo?.firstName === "" || personalInfo?.email ==="" )? "box2col docIcon gap10":"box2col docIcon gap10 active"}>
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="50" height="50" rx="5" fill="#5243AA" fill-opacity="0.05"/>
                            <path d="M35 20.4299V26.4299C35 27.9299 34.5 29.1799 33.62 30.0599C32.75 30.9299 31.5 31.4299 30 31.4299V33.5599C30 34.3599 29.11 34.84 28.45 34.4L24 31.4299H21.88C21.96 31.1299 22 30.8199 22 30.4999C22 29.4799 21.61 28.54 20.97 27.83C20.25 27.01 19.18 26.4999 18 26.4999C16.88 26.4999 15.86 26.96 15.13 27.71C15.04 27.31 15 26.8799 15 26.4299V20.4299C15 17.4299 17 15.4299 20 15.4299H30C33 15.4299 35 17.4299 35 20.4299Z" stroke="#5243AA" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M22 30.5C22 31.25 21.79 31.96 21.42 32.56C21.21 32.92 20.94 33.24 20.63 33.5C19.93 34.13 19.01 34.5 18 34.5C16.54 34.5 15.27 33.72 14.58 32.56C14.21 31.96 14 31.25 14 30.5C14 29.24 14.58 28.11 15.5 27.38C16.19 26.83 17.06 26.5 18 26.5C20.21 26.5 22 28.29 22 30.5Z" stroke="#5243AA" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M16.4399 30.5L17.4299 31.49L19.5599 29.52" stroke="#5243AA" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M21.5 23.5H28.5" stroke="#5243AA" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <p className="text-16">Personal Info</p>
                    </div>
                    <div className={currFomr===2?"box2col docIcon gap10 current active":((bankDetails[0]?.accountHolder === "" || bankDetails[1]?.accountHolder === ""))? "box2col docIcon gap10":"box2col docIcon gap10 active"}>
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="50" height="50" rx="5" fill="#5243AA" fill-opacity="0.05"/>
                            <path d="M35 20.4299V26.4299C35 27.9299 34.5 29.1799 33.62 30.0599C32.75 30.9299 31.5 31.4299 30 31.4299V33.5599C30 34.3599 29.11 34.84 28.45 34.4L24 31.4299H21.88C21.96 31.1299 22 30.8199 22 30.4999C22 29.4799 21.61 28.54 20.97 27.83C20.25 27.01 19.18 26.4999 18 26.4999C16.88 26.4999 15.86 26.96 15.13 27.71C15.04 27.31 15 26.8799 15 26.4299V20.4299C15 17.4299 17 15.4299 20 15.4299H30C33 15.4299 35 17.4299 35 20.4299Z" stroke="#5243AA" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M22 30.5C22 31.25 21.79 31.96 21.42 32.56C21.21 32.92 20.94 33.24 20.63 33.5C19.93 34.13 19.01 34.5 18 34.5C16.54 34.5 15.27 33.72 14.58 32.56C14.21 31.96 14 31.25 14 30.5C14 29.24 14.58 28.11 15.5 27.38C16.19 26.83 17.06 26.5 18 26.5C20.21 26.5 22 28.29 22 30.5Z" stroke="#5243AA" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M16.4399 30.5L17.4299 31.49L19.5599 29.52" stroke="#5243AA" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M21.5 23.5H28.5" stroke="#5243AA" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <p className="text-16">Bank Details</p>
                    </div>
                    <div className={currFomr===3?"box2col docIcon gap10 current active":(educationDetails?.undergradCollege === "" )? "box2col docIcon gap10" :"box2col docIcon gap10 active"}>
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="50" height="50" rx="5" fill="#5243AA" fill-opacity="0.05"/>
                            <path d="M35 20.4299V26.4299C35 27.9299 34.5 29.1799 33.62 30.0599C32.75 30.9299 31.5 31.4299 30 31.4299V33.5599C30 34.3599 29.11 34.84 28.45 34.4L24 31.4299H21.88C21.96 31.1299 22 30.8199 22 30.4999C22 29.4799 21.61 28.54 20.97 27.83C20.25 27.01 19.18 26.4999 18 26.4999C16.88 26.4999 15.86 26.96 15.13 27.71C15.04 27.31 15 26.8799 15 26.4299V20.4299C15 17.4299 17 15.4299 20 15.4299H30C33 15.4299 35 17.4299 35 20.4299Z" stroke="#5243AA" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M22 30.5C22 31.25 21.79 31.96 21.42 32.56C21.21 32.92 20.94 33.24 20.63 33.5C19.93 34.13 19.01 34.5 18 34.5C16.54 34.5 15.27 33.72 14.58 32.56C14.21 31.96 14 31.25 14 30.5C14 29.24 14.58 28.11 15.5 27.38C16.19 26.83 17.06 26.5 18 26.5C20.21 26.5 22 28.29 22 30.5Z" stroke="#5243AA" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M16.4399 30.5L17.4299 31.49L19.5599 29.52" stroke="#5243AA" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M21.5 23.5H28.5" stroke="#5243AA" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <p className="text-16">Education</p>
                    </div>
                    <div className={currFomr===4?"box2col docIcon gap10 current active":(currentWork?.length> 0 && currentWork[0]?.currentCompany === "" || previousWork?.length> 0 && previousWork[0]?.previousDesignation === "")? "box2col docIcon gap10":"box2col docIcon gap10 active"}>
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="50" height="50" rx="5" fill="#5243AA" fill-opacity="0.05"/>
                            <path d="M35 20.4299V26.4299C35 27.9299 34.5 29.1799 33.62 30.0599C32.75 30.9299 31.5 31.4299 30 31.4299V33.5599C30 34.3599 29.11 34.84 28.45 34.4L24 31.4299H21.88C21.96 31.1299 22 30.8199 22 30.4999C22 29.4799 21.61 28.54 20.97 27.83C20.25 27.01 19.18 26.4999 18 26.4999C16.88 26.4999 15.86 26.96 15.13 27.71C15.04 27.31 15 26.8799 15 26.4299V20.4299C15 17.4299 17 15.4299 20 15.4299H30C33 15.4299 35 17.4299 35 20.4299Z" stroke="#5243AA" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M22 30.5C22 31.25 21.79 31.96 21.42 32.56C21.21 32.92 20.94 33.24 20.63 33.5C19.93 34.13 19.01 34.5 18 34.5C16.54 34.5 15.27 33.72 14.58 32.56C14.21 31.96 14 31.25 14 30.5C14 29.24 14.58 28.11 15.5 27.38C16.19 26.83 17.06 26.5 18 26.5C20.21 26.5 22 28.29 22 30.5Z" stroke="#5243AA" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M16.4399 30.5L17.4299 31.49L19.5599 29.52" stroke="#5243AA" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M21.5 23.5H28.5" stroke="#5243AA" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <p className="text-16">Work Experience</p>
                    </div>
                </div>
                <div className="popUpRight">
                    <MasterForm4 isActive={currFomr === 1} personalInfo={personalInfo}  onChange={infoChange} setPhotos={setPhotos} photos={photos} isError={errorMsg} aliasState={aliasState} setAliasState={setAliasState} setPersonalInfo={setPersonalInfo}/>
                    <MasterForm3 isActive={currFomr === 2} bankDetails={bankDetails} onChange={infoBank} setPhotos={setPhotos} photos={photos} setPhotos2={setPhotos2} photos2={photos2} infoBank={infoBank} accountHolderValue={accountHolderValue} accountTypeValue={accountTypeValue}/>
                    <MasterForm2 isActive={currFomr === 3} educationDetails={educationDetails} onChange={infoEducation} otherEducationCount={otherEducationCount} setOtherEducationCount={setOtherEducationCount} handleOtherEducation={handleOtherEducation} setOtherEducation={setOtherEducation} otherEducation={otherEducation}/>
                    <MasterForm1 isActive={currFomr === 4} currentWorkCount={currentWorkCount} setCurrentWorkCount={setCurrentWorkCount} handleCurrentWorkChange={handleCurrentWorkChange} setCurrentWork={setCurrentWork} handlePreviousWorkChange={handlePreviousWorkChange} peviousWorkCount={peviousWorkCount} setPrevioustWorkCount={setPrevioustWorkCount} setPreviousWork={setPreviousWork} currentWork={currentWork} previousWork={previousWork}/>
                    <div className="popupFooter mt-20">
                        <div className="buttonWrapper">
                           {/* { currFomr == 1 ?<Button className={'BlueFillButton'} text={"Bulk upload"}></Button> : ""} */}
                            {/* {console.log(personalInfo,"INFO")} */}
                            {currFomr == 1 ? "" :<Button className={'GreyFillButton'} text={'Back'} onClick={()=> setCurrForm(prev => prev === 1 ? prev : --prev)}/>}
                            <Button className={'BlueFillButton'} text={`${currFomr === 4 ? 'Submit' : 'Next' }`} onClick={()=> {{!Validate() && setCurrForm(prev => prev === 4 ? prev : ++prev);} handleSubmit()} }/>
                        </div>
                    </div>
                </div>
           </div>
        </div>
    </div>
</div>
  )
}

export default MastersDeatailPopup